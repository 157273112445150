import { default as flowplayer } from '@flowplayer/player';
import Hls from '@flowplayer/player/plugins/hls';
import Audio from '@flowplayer/player/plugins/audio';
import Ovp from '@flowplayer/player/plugins/ovp';
import Airplay from '@flowplayer/player/plugins/airplay';
import Chromecast from '@flowplayer/player/plugins/chromecast';
import Subtitles from '@flowplayer/player/plugins/subtitles';
import FloatOnScroll from '@flowplayer/player/plugins/float-on-scroll';
import Keyboard from '@flowplayer/player/plugins/keyboard';
import Preview from '@flowplayer/player/plugins/preview';

import { nb } from '@flowplayer/translations';

Object.assign(flowplayer.i18n, { nb });
flowplayer(
  Hls,
  Ovp,
  Airplay,
  Chromecast,
  Subtitles,
  FloatOnScroll,
  Keyboard,
  Preview,
  Audio
);

export { flowplayer };
