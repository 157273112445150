import '@ungap/with-resolvers';
import { selectVastParameters } from '../model/selectors.js';
import createLogger from './logger.js';

const log = createLogger();

export const BAZAAR_TIMEOUT = 2000;
/**
 * window.bazaar.instream is defined globally before/or after this script has executed
 * (ie. safe for race conditions).
 *
 * Bazaar either defines - or replaces the initalized array - with an object { push: function }.
 * and runs all the provided callbacks.
 *
 * The loadVast function is called with targeting data - and returns a vastUrl (adTag) to be used
 * by the flowplayer instance.
 *
 * https://github.com/amedia/bazaar/blob/master/src/bazaar/instream/instreamRemoteHandler.js#L24
 */
async function asyncAdTagFetcher(store) {
  // ES2024 - polyfilled with @ungap/with-resolvers
  const { resolve, reject, promise } = Promise.withResolvers();
  const timer = setTimeout(() => {
    const error = new Error(
      `Failed to connect to bazaar.instream in ${BAZAAR_TIMEOUT}ms`
    );
    console.warn(error);
    reject(error);
  }, BAZAAR_TIMEOUT);
  window.bazaar = window.bazaar || { instream: [] };
  window.bazaar.instream.push(({ loadVast, version }) => {
    if (!/^1\..*/.test(version)) {
      const error = new Error('Unable to run bazaar version:' + version);
      console.warn(error);
      clearTimeout(timer);
      return reject(error);
    }
    log.info('bazaar.instream:', version);
    log.info('loadVast:', selectVastParameters(store.getState()));
    loadVast(selectVastParameters(store.getState()))
      .then((vastUrl) => {
        log.info('vastUrl:', vastUrl);
        return resolve(vastUrl);
      })
      .catch((error) => {
        log.error('loadVast error:', error);
        return reject(error);
      })
      .finally(() => clearTimeout(timer));
  });
  return promise;
}

export function getImaConfig(store) {
  return {
    ima: {
      ads: [
        {
          time: 0,
          adTag: () => asyncAdTagFetcher(store),
        },
      ],
    },
  };
}
