/* global globalThis */
const FLOWPLAYER_BASE_URL = 'https://cdn.flowplayer.com/releases/native';
const LOADER_NAMESPACE = '__flowplayer_loader__';

globalThis[LOADER_NAMESPACE] = globalThis[LOADER_NAMESPACE] || {};

function loadCss(version) {
  const href = `${FLOWPLAYER_BASE_URL}/${version}/style/flowplayer.css`;
  const links = document.querySelectorAll(`link[href='${href}']`);

  if (links.length === 0) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = href;
    link.setAttribute('data-status', 'loading');
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}

const loadScript = (src, domRef) =>
  new Promise((resolve, reject) => {
    const el = document.createElement('script');
    el.src = src;
    el.onload = resolve;
    el.onerror = reject;
    domRef.appendChild(el);
  });

function loadPlayerScripts({ domRef, version }) {
  if (!globalThis[LOADER_NAMESPACE].player) {
    globalThis[LOADER_NAMESPACE].player = Promise.all(
      [
        `${FLOWPLAYER_BASE_URL}/${version}/flowplayer.min.js`,
        `${FLOWPLAYER_BASE_URL}/${version}/plugins/hls.min.js`,
        `${FLOWPLAYER_BASE_URL}/${version}/plugins/share.min.js`,
      ].map((s) => loadScript(s, domRef))
    );
  }
  return globalThis[LOADER_NAMESPACE].player;
}

function loadTranslationScripts({ domRef }) {
  if (!globalThis[LOADER_NAMESPACE].translations) {
    globalThis[LOADER_NAMESPACE].translations = Promise.all(
      [`${FLOWPLAYER_BASE_URL}/translations/flowplayer.lang.nb.js`].map((s) =>
        loadScript(s, domRef)
      )
    );
    return globalThis[LOADER_NAMESPACE].translations;
  }
}

function loadAdsScripts({ domRef, adsDisabled, version, debugLogging }) {
  if (adsDisabled) {
    return Promise.resolve();
  }
  if (!globalThis[LOADER_NAMESPACE].ads) {
    globalThis[LOADER_NAMESPACE].ads = Promise.all(
      [
        `${FLOWPLAYER_BASE_URL}/${version}/plugins/ads.min.js`,
        `https://imasdk.googleapis.com/js/sdkloader/${
          debugLogging ? 'ima3_debug' : 'ima3'
        }.js`,
      ].map((s) => loadScript(s, domRef))
    );
  }

  return globalThis[LOADER_NAMESPACE].ads;
}

function loadVideoScripts({ domRef, version }) {
  if (!globalThis[LOADER_NAMESPACE].video) {
    globalThis[LOADER_NAMESPACE].video = Promise.all(
      [
        `${FLOWPLAYER_BASE_URL}/${version}/plugins/ovp.min.js`,
        `${FLOWPLAYER_BASE_URL}/${version}/plugins/playlist.min.js`,
        `${FLOWPLAYER_BASE_URL}/${version}/plugins/airplay.min.js`,
        `${FLOWPLAYER_BASE_URL}/${version}/plugins/chromecast.min.js`,
        `${FLOWPLAYER_BASE_URL}/${version}/plugins/subtitles.min.js`,
        `${FLOWPLAYER_BASE_URL}/${version}/plugins/float-on-scroll.min.js`,
        `${FLOWPLAYER_BASE_URL}/${version}/plugins/preview.min.js`,
        `${FLOWPLAYER_BASE_URL}/${version}/plugins/chapters.min.js`,
        `${FLOWPLAYER_BASE_URL}/${version}/plugins/keyboard.min.js`,
      ].map((s) => loadScript(s, domRef))
    );
  }
  return globalThis[LOADER_NAMESPACE].video;
}

function loadAudioScripts({ domRef, version }) {
  if (!globalThis[LOADER_NAMESPACE].audio) {
    globalThis[LOADER_NAMESPACE].audio = Promise.all(
      [`${FLOWPLAYER_BASE_URL}/${version}/plugins/audio.min.js`].map((s) =>
        loadScript(s, domRef)
      )
    );
  }
  return globalThis[LOADER_NAMESPACE].audio;
}

export default async function bootstrapFlowplayer({
  adblocked,
  element,
  version,
  isAudio,
  isAdsDisabled,
  isDebugLogging,
}) {
  loadCss(version);
  await loadPlayerScripts({ domRef: element, version });
  return Promise.all([
    loadTranslationScripts({ domRef: element }),
    isAudio
      ? loadAudioScripts({ domRef: element, version })
      : loadVideoScripts({ domRef: element, version }),
    loadAdsScripts({
      domRef: element,
      adsDisabled: adblocked || isAdsDisabled,
      version,
      debugLogging: isDebugLogging,
    }),
  ]).then(() => globalThis.flowplayer);
}
